<template>
  <b-modal
    id="compose-mail"
    :visible="shallShowEmailComposeModal"
    title="Compose Email"
    footer-class="d-flex justify-content-between"
    body-class="p-0"
    size="lg"
    static
    @change="(val) => $emit('update:shall-show-email-compose-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ $t('Create') }}
      </h5>

      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="saveAsDraft"
        />

        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="discardEmail"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div class="d-flex align-items-center"
           style="row-gap: .5rem; column-gap: .5rem;"
      >
        <!--        <b-dropdown-->
        <!--          v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
        <!--          split-->
        <!--          text="Send"-->
        <!--          variant="primary"-->
        <!--          right-->
        <!--          @click="sendEmail"-->
        <!--        >-->
        <!--          <b-dropdown-item>-->
        <!--            Schedule Send-->
        <!--          </b-dropdown-item>-->
        <!--        </b-dropdown>-->
        <b-button
          variant="primary"
          :disabled="
            !fromIamp
              || !composeData.toAddresses
              || !composeData.toAddresses.length
              || !composeData.subject
              || !composeData.subject.length
              || !composeData.contentHtml
              || !composeData.contentHtml.length
              || isLoading"
          @click="sendEmail(null)"
        >
          {{ $t('Send') }}
        </b-button>

        <drag-drop-upload
          :default-value="composeData.files"
          show-count
          size="sm"
          type="btn"
          :show-files="false"
          @onChangeFiles="changeFiles"
        />

        <!--        <feather-icon-->
        <!--          icon="PaperclipIcon"-->
        <!--          size="17"-->
        <!--          class="ml-2 cursor-pointer"-->
        <!--        />-->
      </div>

      <!-- Footer: Right Content -->
      <!--      <div>-->
      <!--        &lt;!&ndash; Discard Compose &ndash;&gt;-->
      <!--        <feather-icon-->
      <!--          icon="TrashIcon"-->
      <!--          size="17"-->
      <!--          class="ml-75 cursor-pointer"-->
      <!--          @click="discardEmail"-->
      <!--        />-->
      <!--      </div>-->
    </template>

    <!-- Modal: Body -->
    <b-form>
      <!-- Field: From -->
      <div class="compose-mail-form-field">
        <label
          for="email-to"
          class="form-label text-nowrap"
        >
          {{ $t('From') }}:
        </label>
        <sw-select class="mb-n25">
          <v-select
            id="offer-thread-1"
            v-model="fromIamp"
            :options="imapList"
            label="name"
            :filterable="false"
            @option:selected="selectImap"
          >
            <template #no-options>
              {{ $t('NoData') }}
            </template>
          </v-select>
        </sw-select>
      </div>

      <!-- Field: To -->
      <div class="compose-mail-form-field">
        <label
          for="email-to"
          class="form-label text-nowrap"
        >
          {{ $t('To') }}:
        </label>

        <sw-select class="mb-n25">
          <v-select
            id="offer-thread-1"
            v-model="composeData.toAddresses"
            :options="contactList"
            label="email"
            taggable
            multiple
            :filterable="false"
            :selectable="c => {
              if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(c.email))
                return true

              return false
            }"
            :clear-search-on-blur="() => handleBlur('toAddresses')"
            :create-option="createOption"
            @search="loadContacts"
          >
            <template #no-options="{ search }">
              <span v-if="search.length">
                {{ $t('NoData') }}
              </span>

              <span v-else>
                {{ $t('TypeToSearch') }}
              </span>
            </template>
          </v-select>
          <small v-if="search.length"
                 class="text-danger"
          />
        </sw-select>

        <span
          class="cursor-pointer text-nowrap"
          @click="showCcField=!showCcField"
        >Cc</span>

        <span
          class="ml-1 cursor-pointer text-nowrap"
          @click="showBccField=!showBccField"
        >Bcc</span>
      </div>

      <!-- Field: Cc (Hidden Initially) -->
      <div
        v-show="showCcField"
        class="compose-mail-form-field"
      >
        <label
          for="email-cc"
          class="form-label text-nowrap"
        >
          CC:
        </label>

        <sw-select class="mb-n25">
          <v-select
            id="offer-thread-1"
            v-model="composeData.ccAddresses"
            :options="contactList"
            label="email"
            taggable
            multiple
            :filterable="false"
            :selectable="c => {
              if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(c.email))
                return true

              return false
            }"
            :clear-search-on-blur="() => handleBlur('ccAddresses')"
            :create-option="createOption"
            @search="loadContacts"
          >
            <template #no-options="{ search }">
              <span v-if="search.length">
                {{ $t('NoData') }}
              </span>

              <span v-else>
                {{ $t('TypeToSearch') }}
              </span>
            </template>
          </v-select>
        </sw-select>
      </div>

      <!-- Field: Bcc (Hidden Initially) -->
      <div
        v-show="showBccField"
        class="compose-mail-form-field"
      >
        <label
          for="email-bcc"
          class="form-label text-nowrap"
        >
          Bcc:
        </label>

        <sw-select class="mb-n25">
          <v-select
            id="offer-thread-1"
            v-model="composeData.bccAddresses"
            :options="contactList"
            label="email"
            taggable
            multiple
            :filterable="false"
            :selectable="c => {
              if (c.email || /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(c))
                return true

              return false
            }"
            :clear-search-on-blur="() => handleBlur('bccAddresses')"
            :create-option="createOption"
            @search="loadContacts"
          >
            <template #no-options="{ search }">
              <span v-if="search.length">
                {{ $t('NoData') }}
              </span>

              <span v-else>
                {{ $t('TypeToSearch') }}
              </span>
            </template>
          </v-select>
        </sw-select>
      </div>

      <!-- Thread -->
      <div class="compose-mail-form-field">
        <label
          for="email-to"
          class="form-label text-nowrap"
        >
          {{ $t('Thread') }}:
        </label>

        <sw-select
          class="mb-n25"
        >
          <v-select
            id="offer-thread-1"
            v-model="composeData.contactThread"
            :options="threadList"
            label="name"
            :filterable="false"
            @search="loadThreads"
          >
            <template #no-options="{ search }">
              <span v-if="search.length">
                {{ $t('NoData') }}
              </span>

              <span v-else>
                {{ $t('TypeToSearch') }}
              </span>
            </template>

            <template #option="{ name, contact }">
              <div class="d-flex align-items-center">
                <avatar v-if="contact"
                        :user="contact"
                        class="mr-25"
                />  - {{ name }}
              </div>
            </template>

            <template #selected-option="{ name, contact }">
              <div class="d-flex">
                <avatar v-if="contact"
                        :user="contact"
                        class="mr-25"
                /> – {{ name }}
              </div>
            </template>
          </v-select>
        </sw-select>
      </div>

      <!-- Field: Subject -->
      <div class="compose-mail-form-field">
        <label
          for="email-subject"
          class="text-nowrap"
        >
          {{ $t('Subject') }}:
        </label>

        <b-form-input
          id="email-subject"
          v-model="composeData.subject"
        />
      </div>

      <!-- Field: Message - Quill Editor -->
      <div class="message-editor">
        <quill-editor
          id="quil-content"
          key="1"
          v-model="composeData.contentHtml"
          class="quil-content-small"
          :options="editorOption"
        />

        <div
          id="quill-toolbar"
          class="d-flex border-bottom-0"
        >
          <!-- Add a bold button -->
          <button class="ql-bold" />
          <button class="ql-italic" />
          <button class="ql-underline" />
          <button class="ql-align" />
          <button class="ql-link" />
        </div>
      </div>

      <b-form-group v-if="composeData.footer"
                    :label="$t('Footer')"
                    class="px-1"
                    style="color: #b4b7bd"
      >
        <quill-editor
          id="quil-content"
          key="2"
          v-model="composeData.footer"
          class="quil-content-small"
          :options="editorOption"
          disabled
        />
        <!--        <b-form-textarea v-if="composeData.footer"-->
        <!--                         disabled-->
        <!--                         :value="composeData.footer"-->
        <!--                         rows="4"-->
        <!--        >-->
        <!--        </b-form-textarea>-->
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import { BForm, BFormInput } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import * as $i18n from '@core/libs/i18n/utils'
import { CONTACTS_SEARCH, THREADS_SEARCH, USER_MAILBOX } from '@/@constants/fields'
import { GET_USER_MAILBOXES, SEND_EMAIL, UPLOAD_FILES } from '@/@constants/mutations'
import DragDropUpload from '@/views/components/DragDropUpload.vue'
import { mapGetters } from 'vuex'
import { quillEditor } from 'vue-quill-editor'

export default {
  directives: {
    Ripple,
  },
  components: {
    DragDropUpload,

    // BSV
    BForm,
    BFormInput,

    // 3rd Party
    quillEditor,
    vSelect,
  },
  model: {
    prop: 'shallShowEmailComposeModal',
    event: 'update:shall-show-email-compose-modal',
  },
  props: {
    shallShowEmailComposeModal: {
      type: Boolean,
      required: true,
    },
    actionData: {
      type: Object,
      default: null,
    },
  },
  setup(_, { emit }) {
    const composeData = ref({
      toAddresses: [],
      ccAddresses: [],
      bccAddresses: [],
      subject: '',
      contentHtml: '',
      files: [],
    })

    const showCcField = ref(false)
    const showBccField = ref(false)

    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: $i18n.t('Message'),
    }

    /* eslint-disable global-require */
    const emailToOptions = [
      { avatar: require('@/assets/images/avatars/1-small.png'), name: 'Jane Foster' },
      { avatar: require('@/assets/images/avatars/3-small.png'), name: 'Donna Frank' },
      { avatar: require('@/assets/images/avatars/5-small.png'), name: 'Gabrielle Robertson' },
      { avatar: require('@/assets/images/avatars/7-small.png'), name: 'Lori Spears' },
      { avatar: require('@/assets/images/avatars/9-small.png'), name: 'Sandy Vega' },
      { avatar: require('@/assets/images/avatars/11-small.png'), name: 'Cheryl May' },
    ]
    /* eslint-enable global-require */

    const discardEmail = () => {
      composeData.value = {
        contactThread: '',
        toAddresses: [],
        ccAddresses: [],
        bccAddresses: [],
        subject: '',
        contentHtml: '',
        footer: '',
        files: [],
      }

      emit('update:shall-show-email-compose-modal', false)
    }

    return {
      composeData,
      editorOption,
      emailToOptions,
      showCcField,
      showBccField,

      // Email actions
      discardEmail,
    }
  },
  data: () => ({
    isLoading: false,

    fromIamp: '',

    contactList: [],
    threadList: [],
    imapList: [],
    search: '',
    type: '',
    newOption: '',
  }),
  watch: {
    'composeData.toAddresses': function () {
      this.search = ''
    },
  },
  async mounted() {
    if (this.actionData) {
      const {
        // eslint-disable-next-line no-unused-vars
        type, imap, subject, content, contactThread, toAddresses, id, fromMail, deliveredAt,
      } = this.actionData

      // eslint-disable-next-line no-underscore-dangle
      let _subject = ''
      if (type === 'FORWARD') {
        _subject = 'Fwd: '
      } else if (type === 'REPLAY') {
        _subject = 'Re: '
        // if (toAddresses?.length) {
        //   console.log(toAddresses)
        //   this.composeData.toAddresses = toAddresses
        // }
      }

      this.composeData.subject = _subject + (subject ? subject.replaceAll('Fwd:', '').replaceAll('Fwd', '') : '')

      if (type !== 'DRAFT') {
        this.composeData.contentHtml = `<br />-------------------------------------------------------------------------------<br>
        <blockquote>
          <div>${this.$i18n.t('Subject')}: ${subject || '-'}</div>
          <div>${this.$i18n.t('Date')}: ${deliveredAt || '-'}</div>
          <div>${this.$i18n.t('From')}: ${fromMail || '-'}</div>
          <br>${content}</blockquote>`
      } else {
        this.composeData.contentHtml = `${content}`
      }
      if (contactThread) this.composeData.contactThread = contactThread
      if (imap) {
        this.fromIamp = imap
        this.selectImap(imap)
      }
      if (toAddresses) this.composeData.toAddresses = toAddresses
      if (fromMail) this.composeData.toAddresses = [fromMail]
      if (id) this.composeData.id = id

      this.type = type
    }

    await this.loadImapListFull()
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/getCurrentUser',
    }),
  },
  methods: {
    async loadContacts(search, isLoading) {
      this.isLoading = true

      // eslint-disable-next-line no-unused-vars
      const { project, projects } = this

      // eslint-disable-next-line no-unused-vars
      const filters = {}

      if (project) filters.allowedUsers = projects.find(p => p.id === (project?.id || project)).assignedUsers.map(u => u.id)

      this.search = search

      this.contactList = await this.selectSearch(search, isLoading, CONTACTS_SEARCH, 'firstName,lastName', '1/contacts', 25)

      this.isLoading = false
    },

    async loadImapListFull() {
      this.isLoading = true

      const { items } = await this.$store.dispatch(`mailbox/${GET_USER_MAILBOXES}`)

      this.imapList = items.filter(a => a.active)

      this.isLoading = false
    },

    async loadImapList(search, isLoading) {
      this.isLoading = true

      const list = await this.selectSearch(search, isLoading, USER_MAILBOX, 'name', '1/users/me/mailboxes', 25)

      this.imapList = list.filter(i => i.imapPass)

      if (!this.imapList.length && !search.length) await this.loadImapListFull()

      this.isLoading = false
    },

    async loadThreads(search, isLoading) {
      this.isLoading = true

      this.threadList = await this.selectSearch(search, isLoading, THREADS_SEARCH, 'name,contact-firstName,contact-lastName', '1/contacts/threads', 25)

      this.isLoading = false
    },

    async sendEmail(isDraft = false) {
      this.isLoading = true

      const { fromIamp } = this
      const mail = JSON.parse(JSON.stringify(this.composeData))

      if ((fromIamp && mail.subject && mail.contentHtml && mail.toAddresses) || (isDraft)) {
        // if (mail.contactThread) {
        //   mail.contactThread = mail.contactThread.id
        //   delete mail.contactThread
        // }

        let formData = new FormData()
        formData.append('toAddresses[]', mail.toAddresses.map(user => (user.email ? user.email : user)))
        if (mail.ccAddresses.length) formData.append('ccAddresses[]', mail.ccAddresses.map(user => user.email))
        if (mail.bccAddresses.length) formData.append('bccAddresses[]', mail.bccAddresses.map(user => user.email))
        if (mail.contactThread) {
          formData.append('contactThread', this.getObjectId(mail.contactThread))
        }
        formData.append('subject', mail.subject)
        formData.append('contentHtml', !isDraft && this.type !== 'DRAFT' ? `${mail.contentHtml}<br><br>${(mail.footer || '').replaceAll('\n', '<br>')}` : mail.contentHtml)
        if (this.composeData.files) this.composeData.files.forEach(file => formData.append('files[]', file, file.name))

        if (this.type === 'DRAFT') {
          const payload = { uploadedFiles: this.composeData.files.filter(e => !e.token), type: 'building' }
          const { files } = await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, payload)

          formData = {
            files,
            toAddresses: mail.toAddresses.map(user => (user.email ? user.email : user)),
            ccAddresses: mail.ccAddresses.map(user => user.email),
            bccAddresses: mail.bccAddresses.map(user => user.email),
            subject: mail.subject,
            contentHtml: !isDraft ? `${mail.contentHtml}<br><br>${(mail.footer || '').replaceAll('\n', '<br>')}` : mail.contentHtml,
            status: isDraft ? 'DRAFT' : 'QUEUE',
            id: mail.id,
          }

          if (mail.contactThread) {
            formData.contactThread = this.getObjectId(mail.contactThread)
          }

          if (!isDraft) {
            formData.mailboxFolder = 'INBOX.Sent'
          }
        } else if (isDraft) {
          formData.append('status', 'DRAFT')
        } else {
          formData.append('mailboxFolder', 'INBOX.Sent')
        }

        this.$store.dispatch(`globalEmails/${SEND_EMAIL}`, {
          id: fromIamp.id,
          mail: formData,
          mailId: mail.id,
        })
          .then(() => {
            this.$emit('update:shall-show-email-compose-modal', false)
            this.$emit('save', false)
            this.discardEmail()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      }

      this.isLoading = false
    },

    changeFiles(files) { this.composeData.files = files },
    saveAsDraft() {
      const { fromIamp } = this
      const mail = JSON.parse(JSON.stringify(this.composeData))

      if (fromIamp && mail.subject && mail.contentHtml) {
        this.sendEmail(true)
      } else {
        this.$emit('update:shall-show-email-compose-modal', false)
      }
    },
    createOption(value) {
      // this.contactList.push({ email: value })
      this.newOption = ({ email: value })
      return ({ email: value })
    },
    handleBlur(field = 'toAddresses') {
      if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.newOption?.email)) {
        return true
      }

      if (!this.composeData[field].find(e => e?.email === this.newOption.email || e === this.newOption.email)) {
        // Create a new option from the search input
        this.composeData[field].push({ email: this.newOption?.email })
      }

      return true
    },
    selectImap(event) {
      if (event.footer) {
        this.composeData.footer = event.footer
      } else {
        this.composeData.footer = ''
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';

.quil-content-small .ql-editor {
  min-height: 15rem !important;
  max-height: 15rem !important;
}
</style>

<style lang="scss" scoped>
form ::v-deep {

  // Mail To vue-select style
  .v-select {
    .vs__dropdown-toggle {
      border: 0;
      box-shadow: none;
    }
    .vs__open-indicator {
      display: none;
    }
  }

  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }
}
</style>
